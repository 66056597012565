body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(../public/assets/img/bg-topo-square.png);
  background-position: left top;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
