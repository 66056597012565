.card-deck-header {
    margin-bottom: 0.5em;
    .card-body {
        font-size: small;
        padding: 0.5em;
    }
}

.card-deck {
    max-width: unset;
    .row {
        gap: 0.5em;
        justify-content: space-between;
    }
    .col {
        margin-bottom: 0.5em;
    }
    .card {
        margin: 0;
        padding: 0;
        min-width: 250px;
        min-height: fit-content;
        height: 100%;

        .card-body {
            font-size: smaller;

            hr:last-child {
                /* If there's nothing after the hr, then don't display it. */
                display: none;
            }
        }
    }

    /* Using <button class="card" on mobile messes up overall link text */
    > * {
        a:link,
        a:visited,
        a:hover,
        a:active {
            color: var(--app-header-background-color);
        }
    }
}

.card {
    width: 100%;
    padding: 0;
    background-color: var(--app-content-background-color);
    border: 2px solid var(--app-heading-background-color);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    .card-header {
        background-color: var(--app-heading-background-color);
        color: var(--app-heading-color);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-weight: bold;
        padding: 0.25em;
        font-size: 1.1em;
        width: 100%;

        &:has(.card-body) {
            border-bottom: 1px dashed var(--app-heading-color);
            box-shadow: 0 2px 0 var(--app-heading-background-color);
        }
    }

    .card-body {
        width: 100%;
        &:has(.container) {
            /* <Container> has it's own side padding */
            padding-left: 0;
            padding-right: 0;
        }
    }

    .card-footer {
        padding: 0.25em;
        font-size: 1em;
        width: 100%;
    }
}

.card-deck,
.card-deck .row,
.card-deck .row > * {
    /* Override bootstrap's aggressive behaviour */
    margin: unset;
    padding: unset;
}

@media only screen and (max-width: 600px) {
    .card-deck .card {
        min-height: auto;
    }
}
