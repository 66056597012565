.appheader-tooltip {
    font-size: xx-small;
}

header {
    margin-top: 0.75em;

    .card .card-body {
        padding: 0;
    }

    .navbar {
        padding: 0;
        background-color: var(--app-heading-background-color);

        .navbar-brand {
            margin-left: 0px;
            margin-right: 0px;
            width: 1.5em;
            text-align: center;
            color: var(--app-heading-color) !important;
        }

        .nav-link {
            color: var(--app-heading-color) !important;
            border-left: 1px solid #999999;
        }
    }
}
