.hike-list-card {
    margin-bottom: 0.5em;
    .list-group-item:last-of-type {
        margin-bottom: 0;
    }
}

ul.hike-list {
    .list-group-item {
        margin-bottom: 0.5em;
    }

    li {
        border-radius: 6px;
        background-color: var(--app-content-background-color);
        border: 1px solid var(--app-heading-background-color) !important;

        padding: 0.25em 0.5em;
        &.easy-hike .hike-difficulty-icon {
            color: green;
        }
        &.moderate-hike .hike-difficulty-icon {
            color: blue;
        }
        &.hard-hike .hike-difficulty-icon {
            color: black;
        }
        &.unknown-hike .hike-difficulty-icon {
            color: var(--app-content-background-color);
        }
        .hike-info {
            white-space: nowrap;
            text-align: center;
            align-items: stretch;
            a.col:hover {
                background-color: #cecece75;
            }

            .col:first-child {
                text-align: left;
            }
            .col:last-child {
                text-align: right;
            }
        }
    }
}
