:root {
  --app-heading-background-color: #504741;
  --app-heading-color: #bbbbbb;
  --app-content-background-color: #e1d6ce;
}

.app {
  max-width: 1280px;
  margin: 0px auto 2em;
  padding: 0px 24px;

  #content {
    margin-top: 10px;
    min-height: 90vh;
    display: none;
  }

  &.loaded #content {
    display: block;
  }
}
